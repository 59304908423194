// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/Header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/Header.tsx");
  import.meta.hot.lastModified = "1699943151565.9797";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { SearchBar } from '~/components/header/SearchBar';
import { useRootLoader } from '~/utils/use-root-loader';
import { UserIcon } from '@heroicons/react/24/solid';
import { useScrollingUp } from '~/utils/use-scrolling-up';
import { classNames } from '~/utils/class-names';
export function Header({
  onCartIconClick,
  cartQuantity
}) {
  _s();
  const data = useRootLoader();
  const isSignedIn = !!data.activeCustomer.activeCustomer?.id;
  const isScrollingUp = useScrollingUp();
  return <header className={classNames(isScrollingUp ? 'sticky top-0 z-10 animate-dropIn' : '', 'bg-gradient-to-r from-zinc-700 to-gray-900 shadow-lg transform shadow-xl')}>
      <div className="bg-zinc-100 text-gray-600 shadow-inner text-center text-sm py-2 px-2 xl:px-0">
        <div className="max-w-6xl mx-2 md:mx-auto flex items-center justify-between">
          <div>
            <p className="hidden sm:block">

            </p>
          </div>
          <div>
            <Link to={isSignedIn ? '/account' : '/sign-in'} className="flex space-x-1">
              <UserIcon className="w-4 h-4"></UserIcon>
              <span>{isSignedIn ? 'My Account' : 'Sign In'}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto p-4 flex items-center space-x-4">
        <h1 className="text-white w-10">
          <Link to="/">
            <img src="/cube-logo-small.webp" width={40} height={31} alt="Vendure logo" />
          </Link>
        </h1>
        <div className="flex space-x-4 hidden sm:block">
          {data.collections.map(collection => <Link className="text-sm md:text-base text-gray-200 hover:text-white" to={'/collections/' + collection.slug} prefetch="intent" key={collection.id}>
              {collection.name}
            </Link>)}
        </div>
        <div className="flex-1 md:pr-8">
          <SearchBar></SearchBar>
        </div>
        <div className="">
          <button className="relative w-9 h-9 bg-white bg-opacity-20 rounded text-white p-1" onClick={onCartIconClick} aria-label="Open cart tray">
            <ShoppingBagIcon></ShoppingBagIcon>
            {cartQuantity ? <div className="absolute rounded-full -top-2 -right-2 bg-primary-600 w-6 h-6">
                {cartQuantity}
              </div> : ''}
          </button>
        </div>
      </div>
    </header>;
}
_s(Header, "DYOMMUccHym665bWbVYRNKnzlic=", false, function () {
  return [useRootLoader, useScrollingUp];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;